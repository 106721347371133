import styled, { css } from 'styled-components';
import {Dropdown, Modal} from "react-bootstrap";
import { DropdownButton } from "react-bootstrap";
import { StyledDateTime } from '../../../components/CreateEvent/styles';
const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';
const RegularFont = 'Open-Sans-Regular';
const LocationBoldFont = 'Open-Sans-Semibold';

const StyledHeader = styled(Modal.Header)`
  padding: 25px 25px 0px 25px;
  padding-top: 33px !important;
  height: 50px;
background: linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%);
border-radius: 6px 6px 0px 0px;
  .close {
    float: right;
    margin-top: -21px;
    margin-right: 2px;
    color: white;
    font-size: 44px;
    opacity: 0.9;
    cursor: pointer !important;
  }
`;

const StyledBody = styled(Modal.Body)`
  padding: 20px 25px 25px 25px;
`;

const Container = styled.div`
  position: relative;
  .headerName{
text-align: left;
margin-bottom: 5px;
font-family: Rubik-Medium;

 font-weight: 500;
 font-size: 18px;
 line-height: 24px;
 color: #FFFFFF;
 position: relative;
  }
  .subTitle{
    font-size: 14px;
    letter-spacing: 0px;
    color: rgba(103,103,106,0.5);
    font-family: ${MediumFont};
    text-align: center;
  }
  .close{
    position: relative;
    bottom: 5px;
    left: 395px;
    >img{
      width: 20px;
      height: 20px;
    }
  }
  @media (max-width: 720px) {
    width: 100%;
    margin: 0px;
  }
`;

const InputContainer = styled.div`
  margin-top: 0px;

  margin-bottom: 0px;
  position: relative;
  text-align: ${({Align}) => Align ? 'center': 'left'};
  > div {
    display: inline !important;
    > button {
      width: 27px !important;
      height: 27px !important;
    }
  }
  @media(max-width: 500px) {
    width: 80%;
    margin-left: 10%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  ._react-file-reader-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }
  .bulkEmail {
    font-weight: normal;
    text-align: center;
    text-decoration: underline;
    text-decoreation-color: rgb(148,191,62);
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0px;
    color: rgb(148,191,62);
    font-family: ${BoldFont}
  }
  .SuccessUpload {
    .glyphicon-trash {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .background{
      background-color: #fff;
      cursor: pointer;
  }
  >i {
    display: inline-block;
    position: absolute;
    top: 26px;
    font-size: 20px;
    right: 30px;
    cursor: pointer;
    color: rgba(29,29,27,0.5); 
  }
  > div {
    display: inline-block;
    align-items: center;
    font-weight: bold;
    padding: 12px;
    border-radius: 4px;
    .addButton {
      padding: 0px;
      border: none;
      font-size: 20px;
      letter-spacing: 0px;
      color: rgb(255,255,255);
      width: 27px !important;
      height: 27px !important;
      border-radius: 50%;
      background-color: #93bf3d;
      display: inline-block;
      margin: 0px;      
      text-align: center;
      cursor: pointer;
      font-family: ${BoldFont};
      float: left;
    }
    .addMail {
      display: inline-block;
      margin-left: 10px;
      font-size: 12px;
      letter-spacing: 0px;
      color: rgb(51,51,51);
      font-family: ${BoldFont};
      margin-top: 4px;
    }
  }

  .inputbox{
    outline: none;
  box-shadow: none;
  padding: 15px;
  font-size: 14px;
  color: rgb(139,139,139);
  margin-bottom: 20px;
  margin-top: 5px;
  background: rgba(247, 245, 235, 0.50);
  border: 1px solid #9C9C9C;
  box-sizing: border-box;
  border-radius: 6px;
  width: 550px;
  height: 47px;
  position: relative;

  color: black;
  ::placeholder {
    color: #67676A;
    font-family: ${MediumFont};
    font-size: 14px;
  }

  }
`;

const StyledInput = styled.input`
  outline: none;
  box-shadow: none;
  padding: 15px;
  font-size: 14px;
  color: rgb(139,139,139);
  margin-bottom: 20px;
  margin-top: 5px;
  background: rgba(247, 245, 235, 0.50);
  border: 1px solid #9C9C9C;
  box-sizing: border-box;
  border-radius: 6px;
  width: 550px;
  height: 47px;

  color: black;
  ::placeholder {
    color: #67676A;
    font-family: ${MediumFont};
    font-size: 14px;
  }
`;

const SubmitContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  .send {
    padding: 15px 15px;
    cursor: pointer;
    background-color: #159fc9;
    border: 1px solid #159fc9;
    color: white;
    white-space: nowrap;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    font-family: Rubik-Medium;
    width: 200px;
    height: 50px;
    background: #69C2FF;
    border-radius: 5px;
  }
`;

const CustomDateTime = styled(StyledDateTime)`
width: 550px;
height: 47px;
background: rgba(247, 245, 235, 0.50);
box-sizing: border-box;
border-radius: 6px;
position: relative;
font-family: Rubik;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 18px;
input{
  background:rgba(247, 245, 235, 0.50) !important;
}

`;

const FieldTitle = styled.span`
  letter-spacing: 0px;
  color: ${({color}) => color ? color :'#20495E'};
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  font-family: Rubik-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  right: 76px;
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const DateIconContainer = styled.div`
  float: left;
  position: absolute;
  top: 40px;
  right: 10px;
  width: 15px;
  cursor: pointer;
  line-height: 0px;
  background:rgba(247, 245, 235, 0.50);
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  div{
    >img{
    width: 14px;
    min-height: 15px;
    margin-top: 0px;
    position: relative;
  }

  }
  >img{
    width: 14px;
    min-height: 15px;
    margin-top: 0px;
    position: relative;
  }
  > svg {
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    top: ${({ noTop }) => noTop && '0px'};
  }
  .rdt {
    .far fa-clock {
      cursor: ${({ disabled }) => disabled && 'not-allowed !important'}; 
    }
  }
`;

const CalendarContainer = styled.div`
  .rdtPicker {
    width: 250px;
  }
  >label {
    display: block;
  }
  input{
   background:rgba(247, 245, 235, 0.50);
  }
`

const EditProfilePadding = styled.div`
  float: left;
  font-size: 14px;
  position: relative;
    bottom: 4px;
  width: 100%;
  display: ${({displayNone}) => displayNone && 'none'};
  font-family:${RegularFont};
  .btn-group {
    display: block;
    float: left;
    width: 100%;
    width: 550px;
    height: 47px;
    background: rgba(156, 156, 156, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
  
  }

  .dropdown-menu {
    width: 100%;
    max-height: 250px;
    overflow-x: hidden;
    min-width: 100% !important;
    >li>a {
      white-space: normal !important;
    }
  }

  .dropdown-toggle.btn-default:hover, .dropdown open btn-group  {
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
  }

  @media (max-width: 500px) {
    padding: 30px 15px 8px;
  }
`;

const SingleTile = styled.div`
  float: left;
  display: inline-block;
  width: ${({ width }) => width+'%'};
  margin-left: ${({ marginLeft }) => marginLeft+'%'};
  margin-right: ${({ marginRight }) => marginRight ? marginRight+'%' : '0'};
  margin-bottom: 30px;
  @media only screen and (max-width: 500px) {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  > select {
    border-color: #ccc;
    padding: 12px 10px;
    width: 100%;
    text-align: left;
    margin-top: 15px;
    font-size: 14px;
    font-weight: bold;
    height: 46px;
    background-color: rgba(156, 156, 156);
     border: 1px solid #9C9C9C;
  }
  .ceIlNc .rdtPicker {
    width: 100%;
  }
  ${({ hideDropDown }) => hideDropDown && css`
    display: none;
  `}
`;

const SettingsName = styled.span`
  font-size: ${({EditPage}) => EditPage ? '16px' : '16px'};
  text-transform: ${({EditPage}) => EditPage && 'uppercase'};
  font-family: ${LocationBoldFont};
  font-family: 'Rubik-Medium';
  padding-bottom: ${({ padding }) => padding ? '15px ': '0px'};
  color: #20495E;
  float: left;
  .important {
    color: red;
    margin-left: 2px;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

const NewDropdownContainer = styled.div`
  margin-bottom: 30px;
`;

const NewSettingsName = styled.div`
  font-size: 16px;
  text-transform: 'uppercase';
  font-family: ${LocationBoldFont};
  font-family: 'Rubik-Medium';
  padding-bottom: 5px;
  color: #20495E;
  float: left;
  .important {
    color: red;
    margin-left: 2px;
  }
`;

const ActivityDropdown = styled(DropdownButton)`
  padding: 12px 20px 12px 10px;
  width: 100%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${({ showfont }) => showfont ? '16px ': '14px'};
  font-weight: ${({ showfont }) => showfont ? 'normal': 'normal'};
  height: 46px;
  background-color: rgba(247, 245, 235, 0.50);
  font-family: 'Rubik';
  color: black;
  border: 10px solid black;

  .settingOption {
    width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }

  > span {
    top: 10px;
    right: 10px;
    position: absolute;
    float: right;
    display: block;
    margin-top: 7px;
    border-bottom: 5px solid transparent;
    border-left: 7px solid black;
    border-top: 5px solid transparent;
  }
  ${({ opened }) => opened && css`
    > span {
      float: right;
      margin-top: 7px;
      border-top: 7px dashed black;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  `}
  
  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
    font-size: ${({ showfont }) => showfont ? '16px ': '14px'};
    font-weight: ${({ showfont }) => showfont ? ' ': 'bold'};
  }
`;

const EditUserProfileLocation = styled(Dropdown)`
  button{
    width: 100%;
    padding: 12px 20px 12px 10px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: normal;
    height: 46px;
    background-color: rgba(247, 245, 235, 0.5);
    font-family: Rubik;
    color: black;
    border-color: #ccc;
    &:hover{
      background-color: rgba(247, 245, 235, 0.5);
      color: black;
      border-color: #ccc;
    }
    &:focus:active{
      padding: 12px 20px 12px 10px;
      width: 100%;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: normal;
      height: 46px;
      background-color: rgba(247, 245, 235, 0.5);
      font-family: Rubik;
      color: black;
      -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
      box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    }
    &:active{
      color: black;
      background-color: rgba(247, 245, 235, 0.5);
      -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
      box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    }
    &::after{
      top: 35px;
      right: 10px;
      position: absolute;
      float: right;
      display: block;
      margin-top: 7px;
      border-bottom: 5px solid transparent;
      border-left: 7px solid black;
      border-top: 5px solid transparent;
    }
  }
  .dropdown-menu{
    width: 100%;
  }
`;

const ContainerV2 = styled.div`
  position: relative;
  .headerName{
text-align: left;
margin-bottom: 5px;
font-family: Rubik-Medium;

 font-weight: 500;
 font-size: 18px;
 line-height: 24px;
 color: #FFFFFF;
 position: relative;
  }
  .subTitle{
    font-size: 14px;
    letter-spacing: 0px;
    color: rgba(103,103,106,0.5);
    font-family: ${MediumFont};
    text-align: center;
  }
  .close{
    position: relative;
    left: 320px;
    >img{
      width: 20px;
      height: 20px;
    }
  }
  @media (max-width: 720px) {
    width: 100%;
    margin: 0px;
  }
`;

export { StyledBody, StyledHeader, Container, InputContainer, StyledInput, SubmitContainer, CustomDateTime, FieldTitle, DateIconContainer, CalendarContainer, EditProfilePadding, SingleTile, SettingsName, ActivityDropdown, EditUserProfileLocation, NewSettingsName, NewDropdownContainer, ContainerV2 };